import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { PORTAL_API_URL } from '@/config';

export default {
  // getVouchers(ctx, props = {}) {
  //   ctx.$store.commit('toggleLoadingPortalVouchers');
  //   const config = props.query ?
  //     {
  //       params: {
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
  //       }
  //     } :
  //     {};
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/voucher`, config).then(
  //     // (response) => {
  //     //   if (response.status === 200 && response.data.status === 'success') {
  //     //     // ctx.$store.commit('setPortalVouchersList', response.data.data);
  //     //     ctx.$store.commit('pushPortalVouchersList', response.data.data);
  //     //     ctx.totalResultVouchers = response.data.total;
  //     //     if (ctx.$store.state.portalVouchersList.length >= response.data.total) {
  //     //       ctx.canLoadMoreVouchers = false;
  //     //     } else {
  //     //       ctx.canLoadMoreVouchers = true;
  //     //     }
  //     //   } else {
  //     //     VueNotifications.error({ message: response.data.description });
  //     //   }
  //     //   ctx.$store.commit('toggleLoadingPortalVouchers');
  //     // },
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalVouchersList', response.data.data);
  //         let vouchers = response.data.data;
  //         if (vouchers === null) {
  //           vouchers = [];
  //         }
  //         ctx.$store.commit('pushPortalVouchersList', vouchers);
  //         ctx.totalResultVouchers = response.data.total || 0;
  //         if (ctx.$store.state.portalVouchersList.length >= response.data.total) {
  //           ctx.canLoadMoreVouchers = false;
  //         } else {
  //           ctx.canLoadMoreVouchers = true;
  //         }
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalVouchers');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalVouchers');
  //     }
  //   );
  // },
  getVouchers(ctx, props = {}) {
    ctx.$store.commit('toggleLoadingPortalVouchers');
    const config = props.query ?
      {
        params: {
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
            location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }
      } :
      {        params: {
            location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
  with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
        }};
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    if (props.query && props.useLocationFilter) {
      if (ctx.filters?.location &&
        typeof ctx.filters.location === 'object' &&
        ctx.filters.location.data  &&
        typeof ctx.filters.location.data === 'object' ) {
        if (Object.keys(ctx.filters.location.data).length === 0) {
          // console.log('is zero ')
          config.params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
          config.params.with_childs = true;
        } else {
          config.params.location = ctx.filters.location.data.id;
          config.params.with_childs = ctx.filters.location.with_childs;
        }
      }
    }
    Vue.axios.get(`${PORTAL_API_URL || ''}/api/portal/admin/voucher`, config).then(
      // (response) => {
      //   if (response.status === 200 && response.data.status === 'success') {
      //     // ctx.$store.commit('setPortalVouchersList', response.data.data);
      //     ctx.$store.commit('pushPortalVouchersList', response.data.data);
      //     ctx.totalResultVouchers = response.data.total;
      //     if (ctx.$store.state.portalVouchersList.length >= response.data.total) {
      //       ctx.canLoadMoreVouchers = false;
      //     } else {
      //       ctx.canLoadMoreVouchers = true;
      //     }
      //   } else {
      //     VueNotifications.error({ message: response.data.description });
      //   }
      //   ctx.$store.commit('toggleLoadingPortalVouchers');
      // },
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalVouchersList', response.data.data);
          let vouchers = response.data.data;
          if (vouchers === null) {
            vouchers = [];
          }
          ctx.$store.commit('pushPortalVouchersList', vouchers);
          ctx.totalResultVouchers = response.data.total || 0;
          if (ctx.$store.state.portalVouchersList.length >= response.data.total) {
            ctx.canLoadMoreVouchers = false;
          } else {
            ctx.canLoadMoreVouchers = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalVouchers');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalVouchers');
      }
    );
  },

  createVoucher(ctx, voucher) {
    ctx.$store.commit('toggleMutePortalVoucherEdit');
    Vue.axios
      .post(`${PORTAL_API_URL || ''}/api/portal/admin/voucher`, {
        request: [voucher]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('vouchers.notificationVoucherCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        }
      );
  },

  /* CORS error */
  updateVoucher(ctx, voucher) {
    ctx.$store.commit('toggleMutePortalVoucherEdit');
    Vue.axios
      .put(`${PORTAL_API_URL || ''}/api/portal/admin/voucher`, {
        request: [voucher]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('vouchers.notificationVoucherUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        }
      );
  },

  /* CORS error */
  deleteVoucher(ctx, voucher) {
    /* ctx.deletingPageModal = false; */
    ctx.$store.commit('toggleMutePortalVoucherEdit');
    Vue.axios
      .delete(`${PORTAL_API_URL || ''}/api/portal/admin/voucher`, {
        params: {
          ids: [voucher.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('vouchers.notificationVoucherDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalVoucherEdit');
        }
      );
  }
};
